import PrimaryButton from '..//PrimaryButton/PrimaryButton';
import React from 'react';
import { ContentfulCard } from '../../../graphql-types';

import * as styles from './CtaCards.module.scss';
import Card from '@components/Card';

type CtaCardsProps = React.HTMLProps<HTMLDivElement> & {
  cards: Array<ContentfulCard>;
  style?: React.CSSProperties;
};

function CtaCards({ cards, style }: CtaCardsProps) {
  return (
    <div className={styles.ctaCards} style={style}>
      {cards.map(c => {
        const isClickable = !!c.linkText;
        const El = isClickable ? PrimaryButton : 'div';
        const linkProps = isClickable
          ? { asWrapper: true, href: c.linkPath }
          : {};
        return (
          <El
            key={c.title}
            className={styles.card}
            aria-label={c.linkText}
            {...linkProps}
          >
            <Card small theme="white" className={styles.cardChild}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3 style={{ flexGrow: 1 }}>{c.title}</h3>
                <p>{c.subtitle}</p>
                {c.linkText && (
                  <div className={styles.link}>
                    <PrimaryButton
                      theme="soft"
                      icon="ArrowRight"
                      tabIndex={-1}
                    />
                  </div>
                )}
              </div>
            </Card>
          </El>
        );
      })}
    </div>
  );
}

export default CtaCards;
