import React from 'react';
import { graphql, PageProps } from 'gatsby';

import useContentfulImage from '../hooks/useContentfulImage.hook';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';
import SnapScrollContainer from '@components/SnapScrollContainer/SnapScrollContainer';

import HomeHero from '@components/Home/HomeHero';

import Container from '@components/Container';
import Grid, { Row } from '@components/Grid';
import DownloadAppButton from '@src/components/DownloadAppButton/DownloadAppButton';
import {
  HomeQuery,
  ContentfulCard,
  ContentfulCardsSectionComponent,
  ContentfulTextList,
} from '../../graphql-types';
import CtaCards from '@components/CtaCards';
import CardPackageInfo from '@components/Home/CardPackageInfo';
import BottomCta from '@components/Home/BottomCta';
import * as styles from './index.module.scss';
import Footer from '@components/Footer/Footer';
import useIsMobile from '../hooks/useIsMobile.hook';
import useSearchParam from '@src/hooks/useSearchParam.hook';
import UspList, { UspListItem } from '@src/components/UspList/UspList';
import HomeCard from '@src/components/HomeHeroCard';
import { StaticImage } from 'gatsby-plugin-image';
import PrimaryButton from '@src/components/PrimaryButton/PrimaryButton';
import RockerPayHomeCard from '@src/components/RockerPayHomeCard';

const IndexPage: React.FC<PageProps<HomeQuery>> = ({ data }) => {
  const adtractionId = useSearchParam('at_gd');
  if (adtractionId) {
    localStorage.setItem('at_gd', adtractionId);
  }
  const adwordsId = useSearchParam('gclid');
  if (adwordsId) {
    localStorage.setItem('gclid', adwordsId);
  }
  const { contentfulPage } = data;

  const isMobile = useIsMobile();

  const { headLine, pageHeader, pageContent } = contentfulPage;

  const [ordnaCard, lanaCard, betalaCard, sparaCard, payCard] = pageContent as Array<
    ContentfulCard
  >;
  const uspSection = pageContent[5] as ContentfulCardsSectionComponent;

  const ordnaImg = useContentfulImage(ordnaCard.backgroundImage);
  const lanaImg = useContentfulImage(lanaCard.backgroundImage);
  const betalaImg = useContentfulImage(betalaCard.backgroundImage);
  const sparaImg = useContentfulImage(sparaCard.backgroundImage);

  const betalaUspList = (pageContent[7] as ContentfulTextList)
    .advancedTextListItems ?? [];

  const ordnaUspList = (pageContent[8] as ContentfulTextList)
    .advancedTextListItems;
  const sparaUspList = (pageContent[9] as ContentfulTextList)
    .advancedTextListItems;
  const lanaUspList = (pageContent[10] as ContentfulTextList)
    .advancedTextListItems;
  const payUspList = (pageContent[11] as ContentfulTextList)
    .advancedTextListItems;

  return (
    <SnapScrollContainer>
      <Layout
        path="/"
        navbarTheme={isMobile === false ? undefined : 'white'}
        customFooter={<></>}
      >
        <SEO title={headLine} />
        <HomeHero title={pageHeader} />

        <Container style={{ marginBottom: 128 }}>
          <Grid>
            <Row>
              <RockerPayHomeCard
                title={payCard.title}
              >
                <StaticImage style={{ width: '70%', marginTop: -10 }} src="../images/rocker-pay/dhl-delivery.png" alt="DHL Delivery" />
                <UspList items={payUspList as UspListItem[]} />
                <div style={{ width: '100%'}}>
                  <PrimaryButton href='https://pay.rocker.com' color="purple" className={styles.actionButton}>
                    Kom igång
                  </PrimaryButton>
                </div>
              </RockerPayHomeCard>
            </Row>
            <Row>
              <HomeCard
                title={ordnaCard.title}
                subtitle={ordnaCard.subtitle}
                image={ordnaImg}
              >
                <UspList items={ordnaUspList as UspListItem[]} />
                <DownloadAppButton />
              </HomeCard>
            </Row>
            <Row>
              <HomeCard
                title={lanaCard.title}
                subtitle={lanaCard.subtitle}
                image={lanaImg}
                flip={true}
              >
                <UspList items={lanaUspList as UspListItem[]} />
                <DownloadAppButton />
              </HomeCard>
            </Row>
            <Row>
              <HomeCard
                title={betalaCard.title}
                subtitle={betalaCard.subtitle}
                image={betalaImg}
              >
                <UspList items={betalaUspList as UspListItem[]} />
                <DownloadAppButton />
              </HomeCard>
            </Row>
            <Row>
              <HomeCard
                title={sparaCard.title}
                subtitle={sparaCard.subtitle}
                image={sparaImg}
                flip={true}
              >
                <UspList items={sparaUspList as UspListItem[]} />
                <DownloadAppButton />
              </HomeCard>
            </Row>
          </Grid>
        </Container>
        <div className={styles.bottomContainer}>
          <Container>
            <h2 style={{ maxWidth: 678 }}>{uspSection.entryTitle}</h2>
            <CtaCards cards={uspSection.card} />
          </Container>

          <CardPackageInfo />

          <BottomCta />
          <Footer />
        </div>
      </Layout>
    </SnapScrollContainer>
  );
};

export default IndexPage;

export const query = graphql`
  query Home($locale: String) {
    contentfulPage(pageUrl: { eq: "/2.0" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
      }
    }
  }
`;
